import React, { useContext } from "react";
import Navigator from "../components/Navigator";
import { graphql } from "gatsby";
import Seo from "../components/seo";
import * as css from "./ReportDetailTemplate.module.scss";
import Header from "../components/header/Header";
import Profile from "../components/detail/Profile";
import QandA from "../components/detail/QandA";
import ReportPage from "../components/detail/ReportPage";
import PinModal from "../components/detail/PinModal";
import {
  GlobalDispatchContext,
  GlobalStateContext,
} from "../context/GlobalContextProvider";
import MetaControl from "../components/feature/meta-control/MetaControl";

const ReportDetailTemplate = (props) => {
  const pageData = props.data.markdownRemark.frontmatter;
  const state = useContext(GlobalStateContext);
  const dispatch = useContext(GlobalDispatchContext);

  return (
    <main>
      <Seo title={pageData.title} lang={`ja`} />
      <PinModal />
      <Header lang={`ja`} />
      <div className={css.lSingleBody}>
        <article className={css.lSingleBody__main}>
          <div className={css.lSingleBody__main__zoomControl}>
            <MetaControl lang={"ja"} />
          </div>
          <div className={css.docMeta}>
            <h2 className={css.docMeta__title}>{pageData.title}</h2>
            <p className={css.docMeta__sub}>
              文: {pageData.author}･
              <span className={css.docMeta__pageNum}>
                {pageData.pages.length}ページ
              </span>{" "}
              <br />
              手書き文字: {pageData.interpreter.name} (
              {pageData.interpreter.area})
            </p>
            {!pageData.interpreter.isProjectStaff && (
              <div className={css.notes}>
                <p>
                  本レポートはクラウドソーシングサービスを利用し手書で文字起こししました。担当の{pageData.interpreter.name}氏による<span className={css.colorRed}>個人的感想が本文とは別に赤字</span>で記されています。
                </p>
              </div>
            )}
          </div>

          <div className={css.lPages}>
            {pageData.pages.map((page, i) => {
              return (
                <div className={css.lPages__page} key={i}>
                  <ReportPage
                    image={page.image}
                    pins={page.pins}
                    htmlContent={page.htmlContent}
                  />
                </div>
              );
            })}
          </div>

          <div className={css.lQandA}>
            <div className={css.profile}>
              <Profile profile={pageData.authorProfile} lang={`ja`} />
            </div>

            <QandA
              questions={pageData.interpreter.questions}
              name={pageData.interpreter.name}
              area={pageData.interpreter.area}
              flag={pageData.interpreter.flag}
              lang={`ja`}
            />


          </div>
        </article>
        <div
          className={
            css.lSingleBody__nav + ` ` + (!state.showNavigator && css.invisible)
          }
        >
          <Navigator lang={"ja"} />
        </div>

        {!state.showNavigator && (
          <button
            aria-label="Show Navigator"
            className={css.showNavigatorButton}
            onClick={() => {
              dispatch({
                type: `SHOW_NAVIGATOR`,
              });
            }}
            title={`Show Navigator`}
          />
        )}
      </div>
    </main>
  );
};

export default ReportDetailTemplate;

export const pageQuery = graphql`
  query ($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      fields {
        slug
      }
      frontmatter {
        lang
        title
        author
        authorProfile {
          name
          body
        }
        interpreter {
          area
          name
          isProjectStaff
          flag
          questions {
            answer
            question
          }
        }
        pages {
          image {
            childImageSharp {
              fluid(maxWidth: 1600, quality: 90) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          pins {
            image {
              childImageSharp {
                fluid(maxWidth: 1600, quality: 90) {
                  ...GatsbyImageSharpFluid
                }
                fixed(width: 128, height: 128, quality: 90) {
                  ...GatsbyImageSharpFixed_withWebp
                }
              }
            }
            hlink
            label
            video
            x
            y
          }
          htmlContent
        }
      }
    }
  }
`;
